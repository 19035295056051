@charset "UTF-8";
/*!
 *  dotudash ~ projects/homepage
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @version  1.0.0
 */
/** @group project's items container */
.project-list-container {
  max-width: 840px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px; }
  @media (max-width: 840px) {
    .project-list-container {
      max-width: 630px; } }
  @media (max-width: 630px) {
    .project-list-container {
      max-width: 420px; } }
  @media (max-width: 420px) {
    .project-list-container {
      max-width: 210px; } }

.collection {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  font-size: 0; }
  .collection__item {
    display: inline-table;
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    margin: 5px 5px;
    vertical-align: top;
    box-shadow: 0 1px 0 white;
    background: #fff; }
  .collection__item-link {
    display: table-cell;
    width: 100%;
    height: 100%;
    word-break: break-all;
    transition: all .3s ease;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    color: #444;
    border: 4px solid #ccc;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5); }
    .collection__item-link:hover {
      border: 4px solid #c80014; }
    .collection__item_muted .collection__item-link {
      color: #aaa;
      border-color: #eee !important; }

/* @end */
.music-projects {
  display: block;
  width: 100%;
  max-height: 180px;
  padding: 40px 0;
  border: 1px solid #E7E7E7;
  background: #D3DEE7;
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 50%);
  color: #666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06) inset;
  text-align: center;
  transition: color .3s ease; }
  .music-projects__heading-2 {
    display: inline-block;
    font-size: 28px;
    text-transform: uppercase; }
  .music-projects__image {
    display: inline-block;
    background-image: url("./images/music-projects.png");
    background-repeat: no-repeat;
    width: 99px;
    height: 111px;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px; }
    @media (max-width: 420px) {
      .music-projects__image {
        display: none; } }
  .music-projects:hover {
    color: #333; }

.project-list-jumbotron {
  margin-top: -1px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1) inset, 0 -10px 10px -10px rgba(0, 0, 0, 0.1) inset; }
  .project-list-jumbotron__main {
    font-weight: 500;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .project-list-jumbotron__soft {
    font-weight: 100;
    font-size: 22px;
    opacity: .8; }
